import moment from 'moment';

export const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

export const isValidTime = timeString => {
  const correctTimeRegex = /^([01]\d|2[0-3])(:([0-5]\d))?$/;
  return correctTimeRegex.test(timeString);
};

export const isValidDate = dateString => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) {
    return false;
  }

  const d = new Date(dateString);
  console.log('d :>> ', d);
  if (Number.isNaN(d.getTime())) {
    console.log('not a time :>> ');
    return false;
  }
  return d.toISOString().slice(0, 10) === dateString;
};

export const formatDate = date => {
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
  return date.toLocaleDateString('sv-SE', options);
};

export const formatTime = date => {
  const options = { hour12: false, hour: '2-digit', minute: '2-digit' };
  return date.toLocaleTimeString('sv-SE', options);
};

/**
 * @returns Formatted current date.
 */
export const todaysDate = () => formatDate(new Date());

export const isTodayOrLater = dateStr => todaysDate() <= dateStr;

export const startOfYear = date => {
  const year = date.substring(0, 4);
  const newDate = new Date(year, 0, 1);
  return formatDate(newDate);
};

export const endOfYear = date => {
  const year = date.substring(0, 4);
  const newDate = new Date(year, 11, 31);
  return formatDate(newDate);
};

export const startOfMonth = date => {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const newDate = new Date(year, month - 1, 1);
  return formatDate(newDate);
};

export const endOfMonth = date => {
  const year = date.substring(0, 4);
  const month = date.substring(5, 7);
  const newDate = new Date(year, month, 0);
  return formatDate(newDate);
};

export const endOfThisYearDate = () => {
  const today = todaysDate();
  const year = today.substring(0, 4);
  const date = new Date(year, 11, 31);
  return formatDate(date);
};

export const daysBetween = (startDateStr, endDateStr) => {
  const oneDay = 1000 * 60 * 60 * 24;
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  return (start - end) / oneDay;
};

export const getTimeHHMM = dateStr => dateStr.substring(11, 16);

export const getDateRangeListExcludingEndDate = (dateFrom, dateToExcl) => {
  const dateArray = [];
  let currentDate = dateFrom;
  while (currentDate < dateToExcl) {
    dateArray.push(formatDate(new Date(currentDate)));
    const date = new Date(currentDate);
    date.setDate(date.getDate() + 1);
    currentDate = formatDate(date);
  }
  return dateArray;
};

export const getMaxDate = dates => {
  const maxDate = moment.max(dates);
  return maxDate;
};
